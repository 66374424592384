import { LocateFixed, Shuffle } from "lucide-react";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import useWallStore from "../../store/useWallStore";
import { scrollToCenter, scrollToRandom } from "../../utils/scrollToGridItem";

const WallHeader = () => {
  const hasExpandedHotspot = useWallStore((state) =>
    Boolean(state.expandedHotspot)
  );

  return (
    <div className={`wall-top-header ${hasExpandedHotspot ? "hide" : ""}`}>
      <LocateFixed
        size="20"
        color="white"
        opacity={0.5}
        onClick={() => scrollToCenter()}
      />
      <Logo />
      <Shuffle
        size="20"
        color="white"
        opacity={0.5}
        onClick={() => scrollToRandom()}
      />
    </div>
  );
};

export default WallHeader;
