import useWallStore from "../../store/useWallStore";
import HotspotGridItem from "./hotspotGridItem";
// import { useEffect, useRef } from "react";

const HotspotPreviewGrid = ({ id }) => {
  const hotspotList = useWallStore((state) => state.hotspotList);

  return (
    <div className="wall-hotspot-grid">
      {hotspotList.map((hotspot, i) => (
        <HotspotGridItem
          key={`${hotspot.id}-grid${id}`}
          hotspot={hotspot}
          gridId={id}
          index={i}
        />
      ))}
    </div>
  );
};

export default HotspotPreviewGrid;
