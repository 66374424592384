import { useNavigate, useParams } from "react-router-dom";
import HotspotInfo from "../components/wall/hotspotGridItem/HotspotInfo";
import useWallStore from "../store/useWallStore";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { ChevronLeft, Users } from "lucide-react";
import { WallCardImageLinearGradient } from "../constants/gradientDefinitions";
import { ClimbingBoxLoader } from "react-spinners";

const HotspotDetail = () => {
  const { id } = useParams();
  const getHotspotbyId = useWallStore((state) => state.getHotspotById);

  const [hotspot, setHotspots] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      try {
        const hotspot = await getHotspotbyId(parseInt(id));
        setHotspots(hotspot);
        setIsLoading(true);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleBackBtnClick = () => {
    navigate(-1);
  };

  if (isLoading || !hotspot) {
    return (
      <div className="center-container">
        <ClimbingBoxLoader color="#5961FF" size={16} />
      </div>
    );
  }

  return (
    <>
      <motion.div initial={{}} animate={{}} className="hotspot-info-header">
        <ChevronLeft
          size={28}
          className="back-btn"
          onClick={handleBackBtnClick}
        />
        <p>{hotspot.experience}</p>
        <p>|</p>
        <Users size={14} />
        <p>{hotspot.inviteeCount}</p>
      </motion.div>
      <motion.div
        initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        transition={{ type: "spring", duration: 0.5 }}
        className="hotspot-grid-item active intersecting"
        style={{
          backgroundImage: `${WallCardImageLinearGradient}, url("${hotspot.bannerImgUrl}")`,
          borderRadius: "8px",
        }}
      >
        <HotspotInfo
          title={hotspot.title}
          venue={hotspot.venue}
          date={hotspot.date}
          titleFont={hotspot.font}
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: "spring", duration: 1, delay: 0.5 }}
        className="grid-item-hotspot-preview"
      >
        <div className="host-details">
          <span>Hosted By</span>
          <p>{hotspot.host.name}</p>
          {hotspot.host.followerCount && (
            <span>{hotspot.host.followerCount} followers</span>
          )}
        </div>
        <img src={hotspot.host.profileImageUrl} alt="host-img" />
        {hotspot.galleryImgUrls.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Hotspot ${index}`}
            className={(index + 1) % 3 === 0 ? "large" : "small"}
            loading="lazy"
          />
        ))}
      </motion.div>
    </>
  );
};

export default HotspotDetail;
