export const scrollToCenter = () => {
  if (!document) {
    return;
  }
  const element = document.getElementById("center-hotspot-item");
  if (!element) {
    return;
  }
  element.scrollIntoView({
    behavior: "smooth",
  });
};

export const scrollToRandom = () => {
  if (!document) {
    return;
  }
  const allItems = document.querySelectorAll(".hotspot-grid-item");
  if (!allItems) {
    return;
  }
  const randomIndex = Math.floor(Math.random() * allItems.length);
  const randomElement = allItems[randomIndex];
  randomElement.scrollIntoView({
    behavior: "smooth",
  });
};
