import HotspotPreviewGrid from "./HotspotPreviewGrid";
// import { useEffect, useRef } from "react";
import WallHeader from "./WallHeader";

const WallDuplicateRender = () => {
  // const scrollContainerRef = useRef(null);
  // const timeoutRef = useRef(null);

  // useEffect(() => {
  //   const container = scrollContainerRef.current;

  //   const handleScroll = () => {
  //     // Clear the previous timeout
  //     if (timeoutRef.current) {
  //       clearTimeout(timeoutRef.current);
  //     }

  //     // Set a new timeout to calculate and snap
  //     timeoutRef.current = setTimeout(() => {
  //       const items = container.querySelectorAll(".hotspot-grid-item");
  //       const scrollLeft = container.scrollLeft;
  //       const scrollTop = container.scrollTop;
  //       const vw = window.innerWidth;
  //       const vh = window.innerHeight;

  //       const viewportCenterX = scrollLeft + vw / 2;
  //       const viewportCenterY = scrollTop + vh / 2;

  //       let closestItem = null;
  //       let closestDistance = Infinity;

  //       items.forEach((item) => {
  //         const rect = item.getBoundingClientRect();
  //         const centerX = rect.left + rect.width / 2 + scrollLeft; // Adjust with scrollLeft
  //         const centerY = rect.top + rect.height / 2 + scrollTop; // Adjust with scrollTop
  //         const distance = Math.sqrt(
  //           Math.pow(centerX - viewportCenterX, 2) +
  //           Math.pow(centerY - viewportCenterY, 2)
  //         );

  //         if (distance < closestDistance) {
  //           closestDistance = distance;
  //           closestItem = item;
  //         }
  //       });

  //       if (closestItem) {
  //         const targetLeft = closestItem.offsetLeft - (vw / 2) + (closestItem.clientWidth / 2);
  //         const targetTop = closestItem.offsetTop - (vh / 2) + (closestItem.clientHeight / 2);

  //         // Snap to the closest item smoothly
  //         container.scrollTo({
  //           left: targetLeft,
  //           top: targetTop,
  //           behavior: "smooth",
  //         });
  //       }
  //     }, 100); // Adjust the timeout duration as needed
  //   };

  //   container.addEventListener("scroll", handleScroll);

  //   return () => {
  //     clearTimeout(timeoutRef.current); // Clear timeout on cleanup
  //     container.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <div className="wall-landing-container">
      <WallHeader />
      {[...Array(9)].map((_, index) => (
        <HotspotPreviewGrid id={index + 1} key={index + 1} />
      ))}
    </div>
  );
};

export default WallDuplicateRender;
