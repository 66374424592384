import { Users } from "lucide-react";

const HotspotGridItemHeader = ({ type, hostName, hostPic, invitees }) => {
  return (
    <div className="grid-item-header">
      <img className="host-image" src={hostPic} alt={hostName} />
      <div className="hotspot-details">
        <p>{hostName}</p>
        <p>{type}</p>
      </div>
      <div className="invitees">
        <Users size="14" color="grey" />
        <span>{invitees}</span>
      </div>
    </div>
  );
};

export default HotspotGridItemHeader;
