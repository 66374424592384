import dayjs from "dayjs";
import { CalendarCheck, MapPin } from "lucide-react";
// import { ReactComponent as GreenWave } from "../../../assets/svg/green-wave.svg";

const HotspotInfo = ({ title, venue, date, titleFont }) => {
  const dateString = dayjs(date).format("MMM DD 'YY");

  return (
    <div className="wall-hotspot-grid-title">
      {/* <GreenWave /> */}
      <p className={`hotspot-title ${titleFont}`}>{title}</p>
      <div className="hotspot-info">
        <CalendarCheck size={14} color="gray" />
        <span>{dateString}</span>
        <span> | </span>
        <MapPin size={14} color="gray" />
        <span>{venue}</span>
      </div>
    </div>
  );
};

export default HotspotInfo;
