export const VIBRATION_TYPE = Object.freeze({
    SCROLL_HOTSPOTS: 3,
    CLICK_HOTSPOT: 4,
});


const VIBRATION_CONFIG = {
  [VIBRATION_TYPE.SCROLL_HOTSPOTS]: [10],
  [VIBRATION_TYPE.CLICK_HOTSPOT]: [70, 10, 30],
};

export function vibrateDevice(vibration_type) {
  if ("vibrate" in navigator) {
    // Vibration is supported
    navigator.vibrate(VIBRATION_CONFIG[vibration_type]);
  } else {
    // Vibration is not supported
    console.log("Vibration is not supported");
  }
}