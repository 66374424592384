const FONT_CLASSES = [
  // "limelight-regular",
  "gochi-hand-regular",
  "titan-one-regular",
  // "ultra-regular",
  // "rock-salt-regular",
  "abril-fatface-regular",
];

export default function getRandomFontClasses() {
  const randomIndex = Math.floor(Math.random() * FONT_CLASSES.length);
  return FONT_CLASSES[randomIndex];
}
