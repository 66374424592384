import { useEffect, useState } from "react";
import "../styles/wall.scss";
import { getPastHotspots } from "../apis/getPastHotspots";
import useWallStore from "../store/useWallStore";
import { ClimbingBoxLoader } from "react-spinners";
import WallDuplicateRender from "../components/wall/WallDuplicateRender";

const WallLanding = () => {
  const [isLoading, setIsLoading] = useState(true);
  const setHotspotList = useWallStore((state) => state.setHotspotList);
  const init = async () => {
    try {
      setIsLoading(true);
      const hotspots = await getPastHotspots();
      setHotspotList(hotspots);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className="center-container">
        <ClimbingBoxLoader color="#5961FF" size={16} />
      </div>
    );
  }

  return <WallDuplicateRender />;
};

export default WallLanding;
